<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <img
          :src="this.$apiPath + appData.picIdFront"
          width="100%"
          height="100%"
          style="border-radius: 10px"
        />
      </v-col>

<v-col cols="12" md="6">
        <img
          :src="this.$apiPath + appData.picIdBack"
          width="100%"
          height="100%"
          style="border-radius: 10px"
        />
      </v-col>

      <v-col cols="12" md="12">

<v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="appData.fName"
          label="Name"
          outlined
          required
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="appData.lName"
          label="Last Name"
          outlined
          required
          dense
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="appData.dob"
              outlined
              dense
              hide-details
              label="DOB *"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="appData.dob"
            :max="dob_max"
            min="1900-01-01"
            @change="savedob"
          ></v-date-picker>
        </v-menu>
      </v-col>

      
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="appData.idProofType"
          hide-details
          outlined
          dense
          label="ID Proof Type *"
          :items="['Emirates ID', 'Passport','RSA Driving Licence','RSA National ID']"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="appData.idProofNumber"
          hide-details
          outlined
          dense
          label="ID Proof Number *"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="appData.passportExpiryDate"
              outlined
              dense
              hide-details
              label="Expiry Date *"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker1"
            v-model="appData.passportExpiryDate"
            min="2023-01-01"
            max="2033-12-31"
            @change="saveexpirydate"
          ></v-date-picker>
        </v-menu>
      </v-col>

<v-col cols="12" md="6">
        <v-text-field
          v-model="appData.addLine1"
          label="Address Line1"
          outlined
          required
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="appData.addLine2"
          label="Address Line1"
          outlined
          required
          dense
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-spacer></v-spacer>
        <v-btn color="success" @click="btnsave">Save</v-btn>
      </v-col>
    </v-row>

      </v-col>
    </v-row>
     <v-row>
      
     </v-row>
    

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["appData","show"],
  data() {
    return {
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      dob_max: "",
      menu1: false,
      menu: false,
      postUpdate: {
        fName: "",
        lName: "",
        event_id: 0,
        venue_id: 0,
        eaid: 0,
        dob: "",
        addLine1: "",
        addLine2: "",
        idProofType: "",
        idProofNumber: "",
        passportExpiryDate: "",
      },
    };
  },
  watch: {
    menu1(val) {
      val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
    },
  },
  mounted() {
    //console.log(this.appData);
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },
    saveexpirydate(date) {
      this.$refs.menu1.save(date);
    },
    savedob(date) {
      this.$refs.menu.save(date);
    },

    btnsave: async function () {
      this.postUpdate.event_id = this.$store.state.eventInfo.EventId;
      this.postUpdate.venue_id = this.$store.state.eventInfo.VenueId;
      this.postUpdate.eaid = this.$store.state.eventInfo.EventAdminID;
      this.postUpdate.fName = this.appData.fName;
      this.postUpdate.lName = this.appData.lName;
      this.postUpdate.addLine1 = this.appData.addLine1;
      this.postUpdate.addLine2 = this.appData.addLine2;
      this.postUpdate.dob = this.appData.dob;
      this.postUpdate.idProofType = this.appData.idProofType;
      this.postUpdate.idProofNumber = this.appData.idProofNumber;
      this.postUpdate.passportExpiryDate = this.appData.passportExpiryDate;

      console.log(this.appData.application_id);
      console.log(this.postUpdate);
      await this.$axios
        .put(
          "EventAdmin/applicationEditorapprove/" + this.appData.application_id,
          this.postUpdate
        )
        .then((res) => {
          if (res.data.status == "Success") {
           // this.showSnackbar("Success", "Data Updated!");
            this.$emit("hideBasicDilog", { open: false });
          }
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style>
</style>
